<template>
  <el-form
    :model="reportForm"
    :rules="rules"
    ref="reportForm"
    class="report-form margin-top-15"
  >
    <el-form-item required>
      <el-col :span="6" class="padding-lt-0">
        <el-form-item prop="month">
          <el-select
            v-model="reportForm.month"
            @change="changeReportForm"
            placeholder="Month"
          >
            <el-option
              v-for="item in months"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="6" class="padding-lt-0">
        <el-form-item prop="day">
          <el-select
            v-model="reportForm.day"
            @change="changeReportForm"
            placeholder="Day"
          >
            <el-option
              v-for="item in days"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="6" class="padding-lt-0">
        <el-form-item prop="year">
          <el-select
            v-model="reportForm.year"
            @change="changeReportForm"
            placeholder="Year"
          >
            <el-option
              v-for="item in years"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <!-- <el-col :span="11" class="padding-lt-0">
        <el-form-item prop="date">
          <el-date-picker
            type="date"
            placeholder="Pick a date of birthday"
            v-model="reportForm.date"
            @change="changeReportForm"
            default-value="2000-01-01"
            style="width: 100%;"
          ></el-date-picker>
        </el-form-item>
      </el-col> -->
      <el-col :span="6" class="padding-rt-0">
        <el-form-item prop="time">
          <el-time-picker
            placeholder="Pick a time of birthday"
            v-model="reportForm.time"
            @change="changeReportForm"
            format="HH:mm"
            style="width: 100%;"
          ></el-time-picker>
        </el-form-item>
      </el-col>
    </el-form-item>
    <el-form-item prop="name">
      <el-input
        v-model="reportForm.name"
        @change="changeReportForm"
        placeholder="Your Name"
      ></el-input>
    </el-form-item>
    <el-form-item prop="city">
      <el-autocomplete
        class="inline-input width-100"
        v-model="reportForm.city"
        :fetch-suggestions="querySearch"
        placeholder="Сity of birth"
        :trigger-on-focus="false"
        clearable
        value-key="title"
        @select="handleSelect"
        @change="handleChange"
      >
        <template slot-scope="{ item }">
          <div class="city">
            <!-- <img
              :src="
                'https://purecatamphetamine.github.io/country-flag-icons/3x2/' +
                  item.country.iso +
                  '.svg'
              "
              height="15"
              style="height:15px"
            /> -->
            <div class="city-right-side">
              <span class="city-title">
                {{ item.title }}
              </span>
              <span class="country-title">{{ item.country.title }}</span>
            </div>
          </div>
        </template>
      </el-autocomplete>
      <template v-if="results.length === 0 && query">
        <div
          role="region"
          class="el-autocomplete-suggestion el-popper"
          style="transform-origin: center top; z-index: 2086; width: 100%; position: absolute; top: 40px; left: 0px;"
          x-placement="bottom-start"
        >
          <div class="el-scrollbar">
            <div
              class="el-autocomplete-suggestion__wrap el-scrollbar__wrap el-scrollbar__wrap--hidden-default"
            >
              <ul
                class="el-scrollbar__view el-autocomplete-suggestion__list"
                role="listbox"
              >
                <li id="el-autocomplete-8308-item-0" role="option" class="">
                  <div data-v-7ad02228="" class="city">
                    <div data-v-7ad02228="" class="city-right-side">
                      <span data-v-7ad02228="" class="city-title-custom">
                        Choose a larger city in the same time zone as yours.
                      </span>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="el-scrollbar__bar is-horizontal">
              <div
                class="el-scrollbar__thumb"
                style="transform: translateX(0%);"
              ></div>
            </div>
            <div class="el-scrollbar__bar is-vertical">
              <div
                class="el-scrollbar__thumb"
                style="transform: translateY(0%);"
              ></div>
            </div>
          </div>
          <div x-arrow="" class="popper__arrow" style="left: 35px;"></div>
        </div>
      </template>
    </el-form-item>
    <el-form-item>
      <el-col :span="12" class="padding-lt-0 flex justify-end">
        <el-button
          @click="resetForm('reportForm')"
          class="width-100-m width-70"
        >
          Reset
        </el-button>
      </el-col>
      <el-col :span="12" class="padding-rt-0 flex">
        <el-button
          type="primary"
          @click="submitForm('reportForm')"
          class="width-100-m width-70"
        >
          Create
        </el-button>
      </el-col>
    </el-form-item>
  </el-form>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import Api from "@/api/api";
import { commonInitAppMethods } from "@/mixins/common-init-app-methods";
import { commonReportingMethods } from "@/mixins/common-reporting-methods";
import { commonErrorsMethods } from "@/mixins/common-errors-methods";

export default {
  name: "ReportForm",
  data() {
    return {
      rules: {
        name: [
          {
            required: true,
            message: "Please input Your name",
            trigger: "blur"
          },
          {
            min: 3,
            max: 20,
            message: "Length should be 3 to 20",
            trigger: "blur"
          }
        ],
        /* date: [
          {
            required: true,
            message: "Please pick a date",
            trigger: "change"
          }
        ], */
        month: [
          {
            required: true,
            message: "Please enter a month",
            trigger: "change"
          }
        ],
        day: [
          {
            required: true,
            message: "Please enter a day",
            trigger: "change"
          }
        ],
        year: [
          {
            required: true,
            message: "Please enter a year",
            trigger: "change"
          }
        ],
        time: [
          {
            required: true,
            message: "Please pick a time",
            trigger: "change"
          }
        ],
        city: [
          {
            required: true,
            message: "Please enter the city",
            trigger: "blur"
          }
        ]
      },
      month: "",
      months: [
        {
          value: "01",
          label: "01"
        },
        {
          value: "02",
          label: "02"
        },
        {
          value: "03",
          label: "03"
        },
        {
          value: "04",
          label: "04"
        },
        {
          value: "05",
          label: "05"
        },
        {
          value: "06",
          label: "06"
        },
        {
          value: "07",
          label: "07"
        },
        {
          value: "08",
          label: "08"
        },
        {
          value: "09",
          label: "09"
        },
        {
          value: "10",
          label: "10"
        },
        {
          value: "11",
          label: "11"
        },
        {
          value: "12",
          label: "12"
        }
      ],
      day: "",
      days: [
        {
          value: "01",
          label: "01"
        },
        {
          value: "02",
          label: "02"
        },
        {
          value: "03",
          label: "03"
        },
        {
          value: "04",
          label: "04"
        },
        {
          value: "05",
          label: "05"
        },
        {
          value: "06",
          label: "06"
        },
        {
          value: "07",
          label: "07"
        },
        {
          value: "08",
          label: "08"
        },
        {
          value: "09",
          label: "09"
        },
        {
          value: "10",
          label: "10"
        },
        {
          value: "11",
          label: "11"
        },
        {
          value: "12",
          label: "12"
        },
        {
          value: "13",
          label: "13"
        },
        {
          value: "14",
          label: "14"
        },
        {
          value: "15",
          label: "15"
        },
        {
          value: "16",
          label: "16"
        },
        {
          value: "17",
          label: "17"
        },
        {
          value: "18",
          label: "18"
        },
        {
          value: "19",
          label: "19"
        },
        {
          value: "20",
          label: "20"
        },
        {
          value: "21",
          label: "21"
        },
        {
          value: "22",
          label: "22"
        },
        {
          value: "23",
          label: "23"
        },
        {
          value: "24",
          label: "24"
        },
        {
          value: "25",
          label: "25"
        },
        {
          value: "26",
          label: "26"
        },
        {
          value: "27",
          label: "27"
        },
        {
          value: "28",
          label: "28"
        },
        {
          value: "29",
          label: "29"
        },
        {
          value: "30",
          label: "30"
        },
        {
          value: "31",
          label: "31"
        }
      ],
      year: "",
      years: [
        {
          value: "2024",
          label: "2024"
        },
        {
          value: "2023",
          label: "2023"
        },
        {
          value: "2022",
          label: "2022"
        },
        {
          value: "2021",
          label: "2021"
        },
        {
          value: "2020",
          label: "2020"
        },
        {
          value: "2019",
          label: "2019"
        },
        {
          value: "2018",
          label: "2018"
        },
        {
          value: "2017",
          label: "2017"
        },
        {
          value: "2016",
          label: "2016"
        },
        {
          value: "2015",
          label: "2015"
        },
        {
          value: "2014",
          label: "2014"
        },
        {
          value: "2013",
          label: "2013"
        },
        {
          value: "2012",
          label: "2012"
        },
        {
          value: "2011",
          label: "2011"
        },
        {
          value: "2010",
          label: "2010"
        },
        {
          value: "2009",
          label: "2009"
        },
        {
          value: "2008",
          label: "2008"
        },
        {
          value: "2007",
          label: "2007"
        },
        {
          value: "2006",
          label: "2006"
        },
        {
          value: "2005",
          label: "2005"
        },
        {
          value: "2004",
          label: "2004"
        },
        {
          value: "2003",
          label: "2003"
        },
        {
          value: "2002",
          label: "2002"
        },
        {
          value: "2001",
          label: "2001"
        },
        {
          value: "2000",
          label: "2000"
        },
        {
          value: "1999",
          label: "1999"
        },
        {
          value: "1998",
          label: "1998"
        },
        {
          value: "1997",
          label: "1997"
        },
        {
          value: "1996",
          label: "1996"
        },
        {
          value: "1995",
          label: "1995"
        },
        {
          value: "1994",
          label: "1994"
        },
        {
          value: "1993",
          label: "1993"
        },
        {
          value: "1992",
          label: "1992"
        },
        {
          value: "1991",
          label: "1991"
        },
        {
          value: "1990",
          label: "1990"
        },
        {
          value: "1989",
          label: "1989"
        },
        {
          value: "1988",
          label: "1988"
        },
        {
          value: "1987",
          label: "1987"
        },
        {
          value: "1986",
          label: "1986"
        },
        {
          value: "1985",
          label: "1985"
        },
        {
          value: "1984",
          label: "1984"
        },
        {
          value: "1983",
          label: "1983"
        },
        {
          value: "1982",
          label: "1982"
        },
        {
          value: "1981",
          label: "1981"
        },
        {
          value: "1980",
          label: "1980"
        },
        {
          value: "1979",
          label: "1979"
        },
        {
          value: "1978",
          label: "1978"
        },
        {
          value: "1977",
          label: "1977"
        },
        {
          value: "1976",
          label: "1976"
        },
        {
          value: "1975",
          label: "1975"
        },
        {
          value: "1974",
          label: "1974"
        },
        {
          value: "1973",
          label: "1973"
        },
        {
          value: "1972",
          label: "1972"
        },
        {
          value: "1971",
          label: "1971"
        },
        {
          value: "1970",
          label: "1970"
        },
        {
          value: "1969",
          label: "1969"
        },
        {
          value: "1968",
          label: "1968"
        },
        {
          value: "1967",
          label: "1967"
        },
        {
          value: "1966",
          label: "1966"
        },
        {
          value: "1965",
          label: "1965"
        },
        {
          value: "1964",
          label: "1964"
        },
        {
          value: "1963",
          label: "1963"
        },
        {
          value: "1962",
          label: "1962"
        },
        {
          value: "1961",
          label: "1961"
        },
        {
          value: "1960",
          label: "1960"
        },
        {
          value: "1959",
          label: "1959"
        },
        {
          value: "1958",
          label: "1958"
        },
        {
          value: "1957",
          label: "1957"
        },
        {
          value: "1956",
          label: "1956"
        },
        {
          value: "1955",
          label: "1955"
        },
        {
          value: "1954",
          label: "1954"
        },
        {
          value: "1953",
          label: "1953"
        },
        {
          value: "1952",
          label: "1952"
        },
        {
          value: "1951",
          label: "1951"
        },
        {
          value: "1950",
          label: "1950"
        },
        {
          value: "1949",
          label: "1949"
        },
        {
          value: "1948",
          label: "1948"
        },
        {
          value: "1947",
          label: "1947"
        },
        {
          value: "1946",
          label: "1946"
        },
        {
          value: "1945",
          label: "1945"
        },
        {
          value: "1944",
          label: "1944"
        },
        {
          value: "1943",
          label: "1943"
        },
        {
          value: "1942",
          label: "1942"
        },
        {
          value: "1941",
          label: "1941"
        },
        {
          value: "1940",
          label: "1940"
        },
        {
          value: "1939",
          label: "1939"
        },
        {
          value: "1938",
          label: "1938"
        },
        {
          value: "1937",
          label: "1937"
        },
        {
          value: "1936",
          label: "1936"
        },
        {
          value: "1935",
          label: "1935"
        },
        {
          value: "1934",
          label: "1934"
        },
        {
          value: "1933",
          label: "1933"
        },
        {
          value: "1932",
          label: "1932"
        },
        {
          value: "1931",
          label: "1931"
        },
        {
          value: "1930",
          label: "1930"
        },
        {
          value: "1929",
          label: "1929"
        },
        {
          value: "1928",
          label: "1928"
        },
        {
          value: "1927",
          label: "1927"
        },
        {
          value: "1926",
          label: "1926"
        },
        {
          value: "1925",
          label: "1925"
        },
        {
          value: "1924",
          label: "1924"
        },
        {
          value: "1923",
          label: "1923"
        },
        {
          value: "1922",
          label: "1922"
        },
        {
          value: "1921",
          label: "1921"
        },
        {
          value: "1920",
          label: "1920"
        },
        {
          value: "1919",
          label: "1919"
        },
        {
          value: "1918",
          label: "1918"
        },
        {
          value: "1917",
          label: "1917"
        },
        {
          value: "1916",
          label: "1916"
        },
        {
          value: "1915",
          label: "1915"
        },
        {
          value: "1914",
          label: "1914"
        },
        {
          value: "1913",
          label: "1913"
        },
        {
          value: "1912",
          label: "1912"
        },
        {
          value: "1911",
          label: "1911"
        },
        {
          value: "1910",
          label: "1910"
        },
        {
          value: "1909",
          label: "1909"
        },
        {
          value: "1908",
          label: "1908"
        },
        {
          value: "1907",
          label: "1907"
        },
        {
          value: "1906",
          label: "1906"
        },
        {
          value: "1905",
          label: "1905"
        },
        {
          value: "1904",
          label: "1904"
        },
        {
          value: "1903",
          label: "1903"
        },
        {
          value: "1902",
          label: "1902"
        },
        {
          value: "1901",
          label: "1901"
        },
        {
          value: "1900",
          label: "1900"
        }
      ],
      results: [],
      query: ""
    };
  },
  mixins: [commonInitAppMethods, commonReportingMethods, commonErrorsMethods],
  computed: {
    ...mapState(["User"]),
    name: {
      get() {
        return this.$store.state.User.name;
      },
      set(value) {
        this.$store.commit("SET_NAME", value);
      }
    },
    reportForm() {
      return this.$store.state.ReportForm;
    },
    city: {
      get() {
        return this.$store.state.ReportCityId.city;
      },
      set(value) {
        this.$store.commit("SET_REPORT_CITY_ID", value);
      }
    },
    bodyGraph() {
      return this.User.chartBodygraph;
    }
  },
  methods: {
    ...mapMutations(["SET_BODYGRAPH", "IS_EMAIL"]),
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.reportForm.time = new Date(this.reportForm.time);
          const hours = this.reportForm.time.getHours();
          const minutes = this.reportForm.time.getMinutes();
          const birthday = `${this.reportForm.year}-${this.reportForm.month}-${
            this.reportForm.day
          } ${hours < 10 ? "0" + hours : hours}:${
            minutes < 10 ? "0" + minutes : minutes
          }`;
          /* this.reportForm.date = new Date(this.reportForm.date);
          this.reportForm.time = new Date(this.reportForm.time);
          const year = this.reportForm.date.getFullYear();
          const month = this.reportForm.date.getMonth() + 1;
          const day = this.reportForm.date.getDate();
          const hours = this.reportForm.time.getHours();
          const minutes = this.reportForm.time.getMinutes();
          const birthday = `${year}-${month < 10 ? "0" + month : month}-${
            day < 10 ? "0" + day : day
          } ${hours < 10 ? "0" + hours : hours}:${
            minutes < 10 ? "0" + minutes : minutes
          }`; */
          this.createReport(birthday);
        } else {
          // console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      localStorage.removeItem("reportForm");
      localStorage.removeItem("reportCity");
    },
    async createReport(birthday) {
      try {
        const reportParams = {
          birthday,
          city_id: this.city,
          first_name: this.reportForm.name
        };
        const result = await Api.createReport(reportParams);
        if (result.success) {
          // console.log(result.report);
          this.SET_BODYGRAPH(result.report);
          /* if (!localStorage.getItem("isSecondTime")) {
            localStorage.setItem("isSecondTime", true);
          } else {
            this.$store.commit("IS_FIRST_TIME", false);
          } */
          this.matchChannels();
          // Save to localStorage chartBodygraph, Tooltips, Report Id & Stored
          localStorage.setItem("chartBodygraph", JSON.stringify(result.report));
          this.$emit("mathingGatesCentersAndDownloadReport", true);
          this.$emit("newReportCreated", result.report.id);
        }
      } catch (e) {
        console.log(e);
        if (e.response.data.errors.birthday[0] === "Невалидная дата") {
          this.$message.error("Error, invalid birthday.");
        }
      }
    },
    async querySearch(queryString, cb) {
      try {
        const res = await Api.searchSities(queryString);
        this.results = res.cities;
        this.query = queryString;
        const cities = res.cities;
        // console.log(cities);
        const results = queryString
          ? cities.filter(this.createFilter(queryString))
          : cities;
        // call callback function to return suggestions
        cb(results);
      } catch (e) {
        console.log(e);
      }
    },
    createFilter(queryString) {
      return city => {
        return (
          city.title.toLowerCase().indexOf(queryString.toLowerCase()) === 0
        );
      };
    },
    handleSelect(item) {
      this.city = item.id;
      // console.log(this.city);
      this.changeReportForm();
      this.saveCityId("reportCity", item.id);
      this.saveCityId("compositeCity", item.id);
      this.changeCompositeForm();
    },
    handleChange() {
      // Clearing query when click on clear buttom
      if (!this.results.length) {
        this.query = "";
      }
    },
    changeCompositeForm() {
      // Change compositeForm data
      let compositeForm = this.reportForm;
      compositeForm.city2 = "";
      compositeForm.date2 = "";
      compositeForm.name2 = "";
      compositeForm.time2 = "";
      this.setItemToLocalStorage(
        "compositeForm",
        JSON.stringify(compositeForm)
      );
    },
    changeReportForm() {
      this.setItemToLocalStorage("reportForm", JSON.stringify(this.reportForm));
      this.changeCompositeForm();
    },
    saveCityId(city, id) {
      this.setItemToLocalStorage(city, id);
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.city {
  display: flex;
  align-items: center;
}
.city-right-side {
  display: flex;
  margin-bottom: 10px;
}
.city-title {
  font-size: 14px;
  line-height: 17px;
  text-overflow: ellipsis;
  overflow: hidden;
}
.country-title {
  font-size: 12px;
  color: #afafaf;
  padding-left: 7px;
  line-height: 17px;
  text-overflow: ellipsis;
  overflow: hidden;
}
.text-align-left {
  text-align: left;
}
.flex {
  display: flex;
}
.padding-lt-0 {
  padding-left: 0 !important;
}
.padding-rt-0 {
  padding-right: 0 !important;
}
.width-100 {
  width: 100%;
}
.justify-end {
  justify-content: end;
}
.report-form {
  min-width: 500px;
  max-width: 500px;
}
.report {
  min-width: 500px !important;
  max-width: 920px !important;
}
.flex-center {
  display: flex;
  justify-content: center;
}
.flex-column {
  display: flex;
}
.el-col-item {
  margin-bottom: 20px;
}
.flex-column .el-col-item,
.flex-column2 .el-col-item {
  align-self: stretch;
}
.flex-column .el-col-item .el-card,
.flex-column2 .el-col-item .el-card {
  height: 100%;
}
.tip {
  padding: 8px 16px;
  background-color: #f0f0fd;
  border-radius: 4px;
  border-left: 5px solid #bcbeff;
  margin: 15px 10px;
  text-align: left;
  font-size: 16px;
  color: #4c4e86;
  line-height: 1.5em;
}
.width-70 {
  width: 70%;
}
.login-wrapper-desk {
  margin: 0 70px;
}
@media screen and (min-width: 320px) and (max-width: 430px) {
  .flex-column,
  .flex-column2 {
    flex-direction: column;
  }
  .flex-column .el-col-item,
  .flex-column2 .el-col-item {
    align-self: start;
  }
  .report-form,
  .report {
    min-width: initial !important;
  }
  .mob-hide {
    display: none;
  }
  .width-100-m {
    width: 100%;
  }
  .login-wrapper-desk {
    margin: 0;
  }
  .report-form {
    min-width: 320px;
    max-width: 430px;
  }
  .report {
    min-width: 320px !important;
    max-width: 430px !important;
  }
}
</style>
