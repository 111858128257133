<template>
  <div class="about">
    <AfterSuccessRegDialog
      :afterSuccessRegDialog.sync="showSuccessDialog"
      @closeSucRegDialog="closeSuccessRegDialog"
    />
    <LoginDialog
      :showDialogIncome.sync="showDialog"
      :email.sync="email"
      :activeNameTab.sync="activeNameTab"
      @openRemind="showRemindDialog = true"
      @close="closeDialog"
    />
    <RemindDialog
      :showRemindDialogIncome.sync="showRemindDialog"
      :email.sync="email"
      @close="closeRemindDialog"
    />
    <div v-if="!isLogged" style="height:300px">
      <span class="h3">To log in to your account you need to</span>
      <el-button type="text" @click="showDialog = true">
        &nbsp;login or register.
      </el-button>
    </div>
    <div v-else-if="isLogged && !isLoading">
      <el-row type="flex" justify="center" :gutter="20">
        <el-col :xs="24" :xl="24" class="profile">
          <el-card class="box-card el-card-with-bg">
            <div slot="header" class="clearfix">
              <el-row>
                <el-col class="header-flex-lt-xs-center" :span="12" :xs="24">
                  <div
                    class="no-mobile profile-header text-align-left bg-color-white"
                  >
                    <el-link
                      v-if="!isEmailConfirmed"
                      @click="resendConfirmEmail"
                      type="warning"
                    >
                      You need to confirm your email
                    </el-link>
                    <br />
                    {{ profile.first_name }}<br />{{ profile.email }}<br />Your
                    reports:
                  </div>
                  <div class="no-desktop profile-header bg-color-white">
                    <el-link
                      v-if="!isEmailConfirmed"
                      @click="resendConfirmEmail"
                      type="warning"
                    >
                      You need to confirm your email
                    </el-link>
                    <br />
                    {{ profile.first_name }}<br />{{ profile.email }}<br />Your
                    reports:
                  </div>
                </el-col>
                <el-col class="header-flex-rt-xs-center" :span="12" :xs="24">
                  <div>
                    <el-button
                      v-if="isLogged"
                      @click="getExit"
                      type="primary"
                      icon="el-icon-switch-button"
                      size="normal"
                      plain
                      round
                    >
                      <span class="no-mobile">Exit</span>
                    </el-button>
                    <el-button
                      v-else
                      @click="showDialog = true"
                      type="primary"
                      icon="el-icon-user"
                      size="mini"
                      plain
                      round
                    >
                      <span class="no-mobile">Login/Register</span>
                    </el-button>
                  </div>
                </el-col>
              </el-row>
            </div>
            <div
              v-if="profile.user_products && profile.user_products.length"
              class="h3"
            >
              Paid
            </div>
            <div v-if="profile.user_products && profile.user_products.length">
              <el-table
                :data="profile.user_products"
                class="no-mobile"
                style="width: 100%"
              >
                <el-table-column label="Name" min-width="100">
                  <template slot-scope="scope">
                    <span>
                      {{ scope.row.first_name }}
                    </span>
                  </template>
                </el-table-column>
                <el-table-column label="Birthday" min-width="110">
                  <template slot-scope="scope">
                    <span>
                      {{ scope.row.birthday }}
                    </span>
                  </template>
                </el-table-column>
                <el-table-column label="Type" min-width="136">
                  <template slot-scope="scope">
                    <span>
                      {{ scope.row.chart_type }}
                    </span>
                  </template>
                </el-table-column>
                <el-table-column label="Profile" min-width="156">
                  <template slot-scope="scope">
                    <span>
                      {{ scope.row.chart_profile_title }}
                      {{ scope.row.chart_profile }}
                    </span>
                  </template>
                </el-table-column>
                <el-table-column label="Operations" min-width="201">
                  <template slot-scope="scope">
                    <el-link
                      icon="el-icon-view"
                      :href="scope.row.view_url"
                      target="_blank"
                      :underline="false"
                      type="info"
                      class="custom-view"
                    >
                      View
                    </el-link>
                  </template>
                </el-table-column>
              </el-table>
              <div
                class="flex no-desktop"
                v-for="(product, i) in profile.user_products"
                :key="i"
              >
                <el-col :span="8" :xs="24">
                  <el-card shadow="never" class="min-height-160">
                    <ul class="ul-no-li-style">
                      <li v-if="product.first_name">
                        <i class="el-icon-user"></i>
                        <span>{{ product.first_name }}</span>
                      </li>
                      <li v-if="product.birthday">
                        <span>{{ product.birthday }}</span>
                      </li>
                      <li v-if="product.chart_type">
                        <span>{{ product.chart_type }}</span>
                      </li>
                      <li v-if="product.chart_profile">
                        <span
                          >{{ product.chart_profile_title }}
                          {{ product.chart_profile }}
                        </span>
                      </li>
                    </ul>
                    <div>
                      <el-link
                        icon="el-icon-view"
                        :href="product.view_url"
                        target="_blank"
                        :underline="false"
                        type="info"
                        class="custom-view"
                      >
                        View
                      </el-link>
                    </div>
                  </el-card>
                </el-col>
              </div>
            </div>
            <div
              v-if="profile.pro_reports && profile.pro_reports.length"
              class="h3"
            >
              Extended
            </div>
            <div v-if="profile.pro_reports && profile.pro_reports.length">
              <el-table
                :data="profile.pro_reports"
                class="no-mobile"
                style="width: 100%"
              >
                <el-table-column label="Name" min-width="100">
                  <template slot-scope="scope">
                    <span>
                      {{ scope.row.first_name }}
                    </span>
                  </template>
                </el-table-column>
                <el-table-column label="Birthday" min-width="110">
                  <template slot-scope="scope">
                    <span>
                      {{ scope.row.birthday }}
                    </span>
                  </template>
                </el-table-column>
                <el-table-column label="Type" min-width="136">
                  <template slot-scope="scope">
                    <span>
                      {{ scope.row.chart_type }}
                    </span>
                  </template>
                </el-table-column>
                <el-table-column label="Profile" min-width="156">
                  <template slot-scope="scope">
                    <span>
                      {{ scope.row.chart_profile_title }}
                      {{ scope.row.chart_profile }}
                    </span>
                  </template>
                </el-table-column>
                <el-table-column label="Operations" min-width="201">
                  <template slot-scope="scope">
                    <el-button
                      size="mini"
                      type="primary"
                      icon="el-icon-view"
                      @click="getReport(scope.row.id)"
                      round
                    >
                      View
                    </el-button>
                  </template>
                </el-table-column>
              </el-table>
              <div
                class="flex no-desktop"
                v-for="(product, i) in profile.pro_reports"
                :key="i"
              >
                <el-col :span="8" :xs="24">
                  <el-card shadow="never" class="min-height-160">
                    <ul class="ul-no-li-style">
                      <li v-if="product.first_name">
                        <i class="el-icon-user"></i>
                        <span>{{ product.first_name }}</span>
                      </li>
                      <li v-if="product.birthday">
                        <span>{{ product.birthday }}</span>
                      </li>
                      <li v-if="product.chart_type">
                        <span>{{ product.chart_type }}</span>
                      </li>
                      <li v-if="product.chart_profile">
                        <span
                          >{{ product.chart_profile_title }}
                          {{ product.chart_profile }}
                        </span>
                      </li>
                    </ul>
                    <div>
                      <el-button
                        size="small"
                        type="primary"
                        icon="el-icon-view"
                        @click="getReport(product.id)"
                        round
                      >
                        View
                      </el-button>
                    </div>
                  </el-card>
                </el-col>
              </div>
            </div>
            <div
              v-if="
                !profile.reports &&
                  !profile.pro_reports &&
                  !profile.user_products
              "
              class="h3 margin-t-b-25"
            >
              You have no saved charts yet.
            </div>
            <div v-if="profile.reports && profile.reports.length" class="h3">
              Demo
            </div>
            <div v-if="profile.reports && profile.reports.length">
              <el-table
                :data="profile.reports"
                class="no-mobile"
                style="width: 100%"
              >
                <el-table-column label="Name" min-width="100">
                  <template slot-scope="scope">
                    <span>
                      {{ scope.row.first_name }}
                    </span>
                  </template>
                </el-table-column>
                <el-table-column label="Birthday" min-width="110">
                  <template slot-scope="scope">
                    <span>
                      {{ scope.row.birthday }}
                    </span>
                  </template>
                </el-table-column>
                <el-table-column label="Type" min-width="136">
                  <template slot-scope="scope">
                    <span>
                      {{ scope.row.chart_type }}
                    </span>
                  </template>
                </el-table-column>
                <el-table-column label="Profile" min-width="159">
                  <template slot-scope="scope">
                    <span>
                      {{ scope.row.chart_profile_title }}
                      {{ scope.row.chart_profile }}
                    </span>
                  </template>
                </el-table-column>
                <el-table-column label="Operations" min-width="201">
                  <template slot-scope="scope">
                    <el-button
                      size="mini"
                      type="primary"
                      icon="el-icon-view"
                      @click="getReport(scope.row.id)"
                      round
                    >
                      View
                    </el-button>
                    <el-button
                      size="mini"
                      type="danger"
                      icon="el-icon-delete"
                      @click="deleteReport(scope.row.id)"
                      round
                    >
                      Delete
                    </el-button>
                  </template>
                </el-table-column>
              </el-table>
              <div
                class="flex no-desktop"
                v-for="(product, i) in profile.reports"
                :key="i"
              >
                <el-col :span="8" :xs="24">
                  <el-card shadow="never" class="min-height-160">
                    <ul class="ul-no-li-style">
                      <li v-if="product.first_name">
                        <i class="el-icon-user"></i>
                        <span>{{ product.first_name }}</span>
                      </li>
                      <li v-if="product.birthday">
                        <span>{{ product.birthday }}</span>
                      </li>
                      <li v-if="product.chart_type">
                        <span>{{ product.chart_type }}</span>
                      </li>
                      <li v-if="product.chart_profile">
                        <span
                          >{{ product.chart_profile_title }}
                          {{ product.chart_profile }}
                        </span>
                      </li>
                    </ul>
                    <div>
                      <el-button
                        size="small"
                        type="primary"
                        icon="el-icon-view"
                        @click="getReport(product.id)"
                        round
                      >
                        View
                      </el-button>
                      <el-button
                        size="small"
                        type="danger"
                        icon="el-icon-delete"
                        @click="deleteReport(product.id)"
                        round
                      >
                        Delete
                      </el-button>
                    </div>
                  </el-card>
                </el-col>
              </div>
            </div>
            <div
              v-if="profile.composites && profile.composites.length"
              class="h3"
            >
              Demo Composite
            </div>
            <div v-if="profile.composites && profile.composites.length">
              <el-table
                :data="profile.composites"
                class="no-mobile"
                style="width: 100%"
              >
                <el-table-column label="Name" min-width="100">
                  <template slot-scope="scope">
                    <span>
                      {{ scope.row.first_name }}
                    </span>
                    <br />
                    <span>
                      {{ scope.row.first_name2 }}
                    </span>
                  </template>
                </el-table-column>
                <el-table-column label="Birthday" min-width="110">
                  <template slot-scope="scope">
                    <span>
                      {{ scope.row.birthday }}
                    </span>
                    <br />
                    <span>
                      {{ scope.row.birthday2 }}
                    </span>
                  </template>
                </el-table-column>
                <el-table-column label="Type" min-width="136">
                  <template slot-scope="scope">
                    <span>
                      {{ scope.row.chart_type }}
                    </span>
                    <br />
                    <span>
                      {{ scope.row.chart_type2 }}
                    </span>
                  </template>
                </el-table-column>
                <el-table-column label="Profile" min-width="156">
                  <template slot-scope="scope">
                    <span>
                      {{ scope.row.chart_profile_title }}
                      {{ scope.row.chart_profile }}
                    </span>
                    <br />
                    <span>
                      {{ scope.row.chart_profile2_title }}
                      {{ scope.row.chart_profile2 }}
                    </span>
                  </template>
                </el-table-column>
                <el-table-column label="Operations" min-width="201">
                  <template slot-scope="scope">
                    <el-button
                      size="mini"
                      type="primary"
                      icon="el-icon-view"
                      @click="getCompositeReport(scope.row.id)"
                      round
                    >
                      View
                    </el-button>
                    <el-button
                      size="mini"
                      type="danger"
                      icon="el-icon-delete"
                      @click="deleteCompositeReport(scope.row.id)"
                      round
                    >
                      Delete
                    </el-button>
                  </template>
                </el-table-column>
              </el-table>
              <div
                class="flex no-desktop"
                v-for="(composite, i) in profile.composites"
                :key="i"
              >
                <el-col :span="8" :xs="24">
                  <el-card shadow="never" class="min-height-160">
                    <ul class="ul-no-li-style">
                      <li v-if="composite.first_name">
                        <i class="el-icon-user"></i>
                        <span>{{ composite.first_name }}</span>
                      </li>
                      <li v-if="composite.birthday">
                        <span>{{ composite.birthday2 }}</span>
                      </li>
                      <li v-if="composite.chart_type">
                        <span>{{ composite.chart_type2 }}</span>
                      </li>
                      <li v-if="composite.chart_profile">
                        <span
                          >{{ composite.chart_profile2_title }}
                          {{ composite.chart_profile2 }}
                        </span>
                      </li>
                    </ul>
                    <ul class="ul-no-li-style">
                      <li v-if="composite.first_name2">
                        <i class="el-icon-user"></i>
                        <span>{{ composite.first_name2 }}</span>
                      </li>
                      <li v-if="composite.birthday2">
                        <span>{{ composite.birthday2 }}</span>
                      </li>
                      <li v-if="composite.chart_type2">
                        <span>{{ composite.chart_type2 }}</span>
                      </li>
                      <li v-if="composite.chart_profile2">
                        <span
                          >{{ composite.chart_profile2_title }}
                          {{ composite.chart_profile2 }}
                        </span>
                      </li>
                    </ul>
                    <div>
                      <el-button
                        size="small"
                        type="primary"
                        icon="el-icon-view"
                        @click="getCompositeReport(product.id)"
                        round
                      >
                        View
                      </el-button>
                      <el-button
                        size="small"
                        type="danger"
                        icon="el-icon-delete"
                        @click="deleteCompositeReport(product.id)"
                        round
                      >
                        Delete
                      </el-button>
                    </div>
                  </el-card>
                </el-col>
              </div>
            </div>
            <div v-if="rightPartnerId" class="login-wrapper-desk">
              <h3 class="font-weigt-300 margin-bt-0">
                {{ sendApplyForChartOfInformation }}
              </h3>
              <el-link
                :href="sendApplayForChartFormLink"
                target="_blank"
                type="primary2"
              >
                View Content
              </el-link>
            </div>
          </el-card>
        </el-col>
      </el-row>
    </div>
    <div v-else>
      Please wait...
    </div>
  </div>
</template>
<script>
import { mapState, mapMutations } from "vuex";
import Api from "@/api/api";
import { commonInitAppMethods } from "@/mixins/common-init-app-methods";
import { commonReportingMethods } from "@/mixins/common-reporting-methods";
import LoginDialog from "@/components/dialogs/LoginDialog";
import RemindDialog from "@/components/dialogs/RemindDialog";
import AfterSuccessRegDialog from "@/components/dialogs/AfterSuccessRegDialog";

export default {
  components: {
    LoginDialog,
    RemindDialog,
    AfterSuccessRegDialog
  },
  data() {
    return {
      profile: {},
      isLoading: true,
      showDialog: false,
      showRemindDialog: false,
      showSuccessDialog: false,
      sendApplyForChartOfInformation:
        "You can order a complete decoding your chart in one document here",
      sendApplayForChartFormLink:
        "https://life-chart.art/#/?anchor=DesignTranscribed",
      rightPartnerId: false
    };
  },
  mixins: [commonInitAppMethods, commonReportingMethods],
  computed: {
    ...mapState(["User"]),
    isLogged: {
      get() {
        return this.$store.getters.IS_LOGGED;
      },
      set(value) {
        this.$store.commit("IS_LOGIN", value);
      }
    },
    isEmailConfirmed: {
      get() {
        return this.$store.getters.IS_EMAIL_CONFIRMED;
      },
      set(value) {
        this.$store.commit("IS_EMAIL", value);
      }
    },
    name: {
      get() {
        return this.$store.state.User.name;
      },
      set(value) {
        this.$store.commit("SET_NAME", value);
      }
    },
    email: {
      get() {
        return this.$store.state.User.email;
      },
      set(value) {
        this.$store.commit("SET_EMAIL", value);
      }
    },
    activeNameTab: {
      get() {
        return this.$store.state.User.activeNameTab;
      },
      set(value) {
        this.$store.commit("SET_ACTIVE_NAME_TAB", value);
      }
    },
    token: {
      get() {
        return this.$store.state.User.access_token;
      },
      set(value) {
        this.$store.commit("SET_TOKEN", value);
      }
    },
    bodyGraph() {
      return this.User.chartBodygraph;
    },
    compoSite() {
      return this.User.chartComposite;
    }
  },
  mounted() {
    this.initApp();
    this.getProfile();
    setTimeout(() => {
      this.getPartnerId();
      this.defineRightPartnerId();
    }, 100);
  },
  methods: {
    ...mapMutations([
      "SET_BODYGRAPH",
      "SET_COMPOSITE",
      "SET_PROFILE",
      "SET_PARTNER_ID",
      "IS_EMAIL"
    ]),
    async getProfile() {
      if (this.isLogged) {
        try {
          const result = await Api.getProfile();
          if (result.success) {
            // console.log(result);
            this.profile = result.profile;
            // console.log("this.profile: ", this.profile);
            this.SET_PROFILE(result.profile);
            this.isLoading = false;
            if (result.profile.is_email_confirmed) {
              this.IS_EMAIL(true);
            } else {
              this.IS_EMAIL(false);
            }
          }
        } catch (e) {
          console.log(e);
        }
      }
    },
    async getReport(reportId) {
      try {
        const result = await Api.getReport(reportId);
        if (result.success) {
          console.log(result.report);
          this.SET_BODYGRAPH(result.report);
          this.matchChannels();
          // Save to localStorage chartBodygraph, Tooltips, Report Id & Stored
          localStorage.setItem("chartBodygraph", JSON.stringify(result.report));
          this.$router.push("/");
          /* this.mathingGates();
          this.mathingCenters(); */
        }
      } catch (e) {
        console.log(e);
      }
    },
    async getCompositeReport(reportId) {
      try {
        const result = await Api.getCompositeReport(reportId);
        if (result.success) {
          console.log(result.report);
          this.SET_COMPOSITE(result.report);
          // Save to localStorage chartComposite, Tooltips, Report Id & Stored
          localStorage.setItem("chartComposite", JSON.stringify(result.report));
          this.$router.push("/composite");
          this.matchCompositeChannels();
          /* this.mathingGates();
          this.mathingCenters(); */
        }
      } catch (e) {
        console.log(e);
      }
    },
    async getExit() {
      this.name = "";
      this.token = "life-chart-token";
      this.isLogged = false;
      localStorage.removeItem("name");
      localStorage.removeItem("token");
      // localStorage.removeItem("chartBodygraph");
      /* try {
        const data = {};
        const result = await Api.logout(data);
        if (result.success) {
          this.name = "";
          this.token = "life-chart-token";
          this.isLogged = false;
          localStorage.removeItem("name");
          localStorage.removeItem("token");
        }
      } catch (e) {
        console.log(e);
      } */
    },
    closeDialog(val) {
      if (val === "regSuccess") {
        this.showSuccessDialog = true;
      }
      this.showDialog = false;
      this.getProfile();
    },
    closeRemindDialog() {
      this.showRemindDialog = false;
    },
    closeSuccessRegDialog(v) {
      this.showSuccessDialog = v;
    },
    async deleteReport(id) {
      try {
        const reportParams = {
          report_id: id
        };
        const result = await Api.deleteReport(reportParams);
        if (result.success) {
          // console.log(result.report);
          this.bodyGraph.is_stored = 0;
          localStorage.setItem(
            "chartBodygraph",
            JSON.stringify(this.bodyGraph)
          );
          this.getProfile();
        }
      } catch (e) {
        console.log(e);
      }
    },
    async deleteCompositeReport(id) {
      try {
        const reportParams = {
          report_id: id
        };
        const result = await Api.unstoreComposite(reportParams);
        if (result.success) {
          // console.log(result.report);
          this.compoSite.is_stored = false;
          localStorage.setItem(
            "chartComposite",
            JSON.stringify(this.compoSite)
          );
          this.getProfile();
        }
      } catch (e) {
        console.log(e);
      }
    },
    async resendConfirmEmail() {
      try {
        const result = await Api.sendSignupEmail();
        if (result.success) {
          this.$message({
            message: "Email resent",
            type: "success"
          });
        }
      } catch (e) {
        console.log(e);
      }
    },
    getPartnerId() {
      const instance = document.getElementById("wiget");
      if (instance) {
        this.SET_PARTNER_ID(instance.dataset.partnerId);
        // console.log("instance is: ", instance.dataset.partnerId);
      }
    },
    defineRightPartnerId() {
      this.rightPartnerId =
        this.$store.state.http_options.headers["Partner-Id"] &&
        +this.$store.state.http_options.headers["Partner-Id"] !== 1;
    }
  }
};
</script>
<style scoped>
.text-align-left {
  text-align: left;
}
.ul-no-li-style {
  text-align: left;
  padding-left: 0 !important;
}
.ul-no-li-style li {
  list-style-type: none;
}
.ul-no-li-style li i {
  padding-right: 5px;
}
.min-height-160 {
  min-height: 100px;
}
.flex {
  display: flex;
}
.profile {
  min-width: 768px;
  max-width: 920px;
}
.profile-header {
  font-size: 18px;
  color: #0000009e !important;
}
.h3 {
  font-size: 16px !important;
  color: #0000009e !important;
  margin-top: 25px !important;
}
.margin-t-b-25 {
  margin: 25px 0 25px !important;
}
@media screen and (min-width: 320px) and (max-width: 430px) {
  .flex {
    flex-direction: column;
  }
  .profile {
    min-width: initial;
  }
}
.h3 {
  font-size: larger;
  font-weight: bold;
}
</style>
